import '../styles/noir-pro/styles.css';
import '../styles/global.css';
import '../styles/styles.css';

export const config = {
  maxDuration: 30,
};

import Bugsnag from '@bugsnag/js';
import type { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React, { useEffect, useMemo } from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Button, Layout } from 'antd';
import moment from 'moment';
import { Analytics } from '@vercel/analytics/react';
import { useRouter } from 'next/router';

import { ProvideAuth } from 'src/util/use-auth';
import { ProvideAxios } from 'src/util/use-api';
import { ProvideLayout } from 'src/util/use-layout';
import AppLayout from 'src/Layout';
import { ProvideWebsocket } from 'src/util/use-websocket';
import { APIContextProvider } from 'src/util/context/APIContext';
import { BinderContextProvider } from 'src/util/context/BinderContext';
import { PermissionsContextProvider } from 'src/util/context/PermissionsContext';
import { composeWrappers } from 'src/util/composeWrappers';
import { SideBar } from 'src/components/SideBar';
import { PortfolioContextProvider } from 'src/util/context/PortfolioContext';
import { GA_TRACKING_ID, pageView } from 'src/util/gtag';

let ErrorBoundary: any = undefined;
// @ts-ignore
if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY && !Bugsnag._client) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
  });
  ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
}

const ErrorView = ({ error }: { error: Error }) => {
  const { t } = useTranslation('common');

  return (
    <Layout
      className={`gx-app-layout full-scroll horizontal-layout full-layout`}
    >
      <Layout>
        <Layout.Content className={`gx-layout-content gx-container-wrap`}>
          <div
            className="gx-main-content-wrapper"
            style={{ position: 'relative' }}
          >
            <SideBar title={t('An error occurred')}>
              <p>
                {t(
                  'A website error occurred, this error has been automatically reported. We hope to resolve this issue as soon as possible, contact support for more information.'
                )}
              </p>
              <Button
                danger
                href={`mailto:support@businessradar.com?subject=${t(
                  'Website error occurred'
                )}&body=Hi, I just experienced a front-end error. %0D %0D URL: ${
                  window.location
                } %0D %0D Error: ${error.message}`}
              >
                {t('Contact Technical Support')}
              </Button>
            </SideBar>
          </div>
          <Layout.Footer>
            <div className="gx-layout-footer-content">
              ©{moment().year()} Business Radar
            </div>
          </Layout.Footer>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const RootWrapper = composeWrappers([
  ({ children }) => (
    <QueryClientProvider client={client}>{children}</QueryClientProvider>
  ),
  ({ children }) => {
    if (!process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
      return <>{children}</>;
    }

    // Create ErrorBoundary with useMemo to avoid creating a new instance on every render
    const ErrorBoundary = useMemo(() => {
      // @ts-ignore
      if (!Bugsnag._client) {
        Bugsnag.start({
          apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
          plugins: [new BugsnagPluginReact()],
        });
      }
      return Bugsnag.getPlugin('react')?.createErrorBoundary(React);
    }, []);

    return (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>
    );
  },
  ({ children }) => <ProvideAxios>{children}</ProvideAxios>,
  ({ children }) => <ProvideAuth>{children}</ProvideAuth>,
  ({ children }) => <APIContextProvider>{children}</APIContextProvider>,
  ({ children }) => <ProvideWebsocket>{children}</ProvideWebsocket>,
  ({ children }) => <ProvideLayout>{children}</ProvideLayout>,
  ({ children }) => <BinderContextProvider>{children}</BinderContextProvider>,
  ({ children }) => (
    <PortfolioContextProvider>{children}</PortfolioContextProvider>
  ),
  ({ children }) => (
    <PermissionsContextProvider>{children}</PermissionsContextProvider>
  ),
  ({ children }) => <AppLayout>{children}</AppLayout>,
]);

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // @ts-ignore
    if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY && !Bugsnag._client) {
      Bugsnag.start({
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
        plugins: [new BugsnagPluginReact()],
      });
    }
  }, []);

  return (
    <>
      {GA_TRACKING_ID ? (
        <Script
          id={'ga-script-import'}
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        />
      ) : null}
      {GA_TRACKING_ID ? (
        <Script
          id={'ga-script'}
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GA_TRACKING_ID}', {
                    page_path: window.location.pathname,
                  });
                  console.log("GA loaded")
                `,
          }}
        />
      ) : null}
      <Head>
        <title>Business Radar</title>
      </Head>

      <RootWrapper>
        <Hydrate state={pageProps.dehydratedState}>
          <Component {...pageProps} />
          <ReactQueryDevtools initialIsOpen={false} />
          <Analytics />
        </Hydrate>
      </RootWrapper>
    </>
  );
};

export default appWithTranslation(App);
